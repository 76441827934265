import React, { useState } from 'react';


function Page1() {
  const [volume, setVolume] = useState(0);
  const [height, setHeight] = useState(0);
  const [resultR, setResultR] = useState(0);
  const [resultr, setResultr] = useState(0);

  const handleChange = (e: any) => {
    setVolume(e.target.value);
  }

  const handleChange2 = (e: any) => {
    setHeight(e.target.value);
  }

  const calculate = () => {

    let k = volume * 3 / height;

    console.log(k % 3);

    if (k % 3 === 0) {
      let m = 0;
      let t = k / 3;
      for (let i = 0; i < t; i++) {
        console.log(i);
        if ((i + 1) * (i + 1) >= t){
          m = i;

          break;
        }

      }

      for (let j = 1; j < m; j++) {
        if ((Math.sqrt((t * 4 - j * j) / 3)) % 1 === 0) {
          m = j;
          break;
        }
      }

      let q = (Math.sqrt((t * 4 - m * m) / 3) - m) / 2;
      

      let R = m +(q * 3);
      let r = m;
      setResultR(R);
      setResultr(r);
    } else if (k % 3 > 0 && k % 3 <= 1) {
      let n = 0;
      k = Math.ceil(k);
      for (let i = 0; i < 4 * k; i++) {
        if ((i + 1) * (i + 1) >= 4 * k){
          n = i;

          break;
        }

      }

      let j = 1
      for (j; j < n; j++) {
        if ((Math.sqrt((k * 4 - j * j) / 3)) % 1 === 0) {
          n = j;
          break;
        }
      }

      if ((Math.sqrt((k * 4 - j * j) / 3)) % 1 === 0) {
        let m = (Math.sqrt((k * 4 - n * n) / 3) - n) / 2;

        let R = m + n;
        let r = m;
        setResultR(R);
        setResultr(r);
      } else {

        alert('ไม่มีคำตอบ');
        setResultR(0);
        setResultr(0);

      }


    } else if (k % 3 === 2) {
    
      alert('ไม่มีคำตอบ');
      setResultR(0);
      setResultr(0);

    }


  }

  return (
    <div className='bg-light shadow-sm p-4'>
        <h4>วิธีการตรวจสอบค่าของปริมาตรรูปทรงกรวยยอดตัด
ที่ส่งผลให้รัศมีของพื้นที่หน้าตัดทั้งสองด้านเป็นจำนวนเต็ม</h4>
        <form className='container'>
        <div className="mb-3">
          <label htmlFor="" className="form-label">ปริมาตร</label>
          <input type="text" className="form-control" id="" aria-describedby="" onChange={handleChange} value={volume} />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">สูง</label>
          <input type="text" className="form-control" id="exampleInputPassword1" onChange={handleChange2} value={height} />
        </div>
        <button type="button" onClick={calculate} className="btn btn-primary">คำนวณ</button>
      </form>
      <div>
        <h4>คำตอบ</h4>
        <p>รัศมีของพื้นที่หน้าตัดทั้งสองด้านเป็นจำนวนเต็ม</p>
        <h3>วงใหญ่ {resultR}</h3>
        <h3>วงเล็ก {resultr}</h3>
      </div>
    </div>
  )
}

export default Page1