import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Page1 from './pages/page1';
import Page2 from './pages/page2';


function App() {
  return (
<div className="App">
      <BrowserRouter>

      <div className="wrapper d-flex align-items-stretch">
			<nav id="sidebar">
				<div className="p-4 pt-5">
		  		<h1><Link to="/" className="logo">Main</Link></h1>
	        <ul className="list-unstyled components mb-5">
            <li>
              <Link to="/">Calculator</Link>
	          </li>
            <li>
              <Link to="/report">Report</Link>
	          </li>
	        </ul>
	      </div>
    	</nav>

      <div id="content" className="p-4 p-md-5 pt-5">

      <Routes>
          <Route path="/" element={<Page1 />} />
          <Route path="/report" element={<Page2 />} />
        </Routes>
        </div>
		</div>


      </BrowserRouter>

    </div>
  );
}

export default App;
